import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import i18n from '../i18n';

Vue.use(VueRouter);

const routes = [
    /**
     * Guest routes
     */
    {
        path: '/login',
        name: 'login',
        component: () => import('./../views/auth/Login.vue'),
        meta: {
            guest: true
        }
    },
    {
        path: '/forgot-password/',
        name: 'forgot-password',
        component: () => import('./../views/auth/ForgotPassword.vue'),
        props: true,
        meta: {
            guest: true
        }
    },
    {
        path: '/reset-password/:reset_token',
        name: 'reset-password',
        component: () => import('./../views/auth/ResetPassword.vue'),
        props: true,
        meta: {
            guest: true
        }
    },
    /**
     * Auth routes
     */
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: {
            auth: true
        }
    },
    // clients
    {
        path: '/clients',
        name: 'clients',
        component: () => import('../views/clients/Clients.vue'),
        meta: {
            auth: true,
            permission: 'view_client'
        }
    },
    // invoices
    {
        path: '/invoice_list/:item_id',
        name: 'invoice_list',
        component: () => import('../views/invoices/InvoiceList.vue'),
        props: (route) => ({
            item_id: route.params.item_id
        }),
        meta: {
            auth: true,
            permission: 'view_invoice'
        }
    },
    {
        path: '/create_invoice',
        name: 'create_invoice',
        component: () => import('../views/invoices/Invoice.vue'),
        meta: {
            auth: true,
            permission: 'view_invoice'
        }
    },
    {
        path: '/edit_invoice/:item_id',
        name: 'edit_invoice',
        component: () => import('../views/invoices/Invoice.vue'),
        props: (route) => ({
            item_id: route.params.item_id,
            title: i18n.t('invoices.update')
        }),
        meta: {
            auth: true
        }
    },
    {
        path: '/invoice_pdf/:item_id',
        name: 'invoice_pdf',
        component: () => import('../views/invoices/InvoicePDF.vue'),
        props: (route) => ({
            item_id: route.params.item_id
        }),
        meta: {
            auth: false
        }
    },
    // services
    {
        path: '/services',
        name: 'services',
        component: () => import('../views/services/Services.vue'),
        meta: {
            auth: true,
            permission: 'manage_services'
        }
    },
    // products
    {
        path: '/products',
        name: 'products',
        component: () => import('../views/products/Products.vue'),
        meta: {
            auth: true,
            permission: 'manage_products'
        }
    },
    // product categoriess
    {
        path: '/product-categories',
        name: 'product-categories',
        component: () => import('../views/products/Categories.vue'),
        meta: {
            auth: true,
            permission: 'manage_product_categories'
        }
    },
    // calendar
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('../views/Calendar.vue'),
        meta: {
            auth: true,
            permission: 'manage_calendar'
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


router.beforeEach(async (to, from, next) => {

    const guest = to.matched.some(record => record.meta.guest);
    const auth = to.matched.some(record => record.meta.auth);

    // Do not allow user to visit login page page if they are logged in
    if (guest && store.getters.isLoggedIn) {
        return next('/');
    }

    // Do not allow user to visit pages that require authentication
    if (auth && !store.getters.isLoggedIn) {
        return next({
            path: '/login'
        });
    }

    // If access to routes requires permission
    if (to.meta.permission !== undefined) {
        if (!(await store.dispatch('hasPermission', to.meta.permission))) {
            return next('/page-not-found');
        }
    }

    next();
});

export default router
