<template>

  <v-app>

    <!--sidebar-->
    <Sidebar v-if="isAuthenticated" :drawer="drawer" :mini_menu="mini_menu"
             @drawerChanged="drawerChanged" @miniMenuChanged="miniMenuChanged"></Sidebar>

    <!--header-->
    <Header :isAuthenticated="isAuthenticated" :drawer="drawer" :mini_menu="mini_menu"
            @drawerChanged="drawerChanged" @miniMenuChanged="miniMenuChanged"></Header>

    <!--content-->
    <v-main>
      <ResponseNotification></ResponseNotification>
      <router-view></router-view>
    </v-main>

    <!--footer-->
    <Footer></Footer>

  </v-app>
</template>

<script>

    import Header from './components/Header.vue'
    import Sidebar from './components/Sidebar.vue'
    import Footer from './components/Footer.vue'
    import ResponseNotification from './components/ResponseNotification.vue'

    export default {
        name: 'App',

        components: {
            Header, Sidebar, Footer, ResponseNotification
        },
        data: () => ({
            drawer: true,
            mini_menu: false
        }),
        computed: {
            isAuthenticated() {
                return this.$store.getters.isLoggedIn && this.$store.getters.showContent;
            }
        },
        methods: {
            // change on drawer
            drawerChanged(val) {
                this.drawer = val;
            },
            // change on menu mini
            miniMenuChanged(val) {
                this.mini_menu = val;
            }
        }

    };
</script>
