import i18n from "../i18n";

let pdfMake = require('pdfmake/build/pdfmake.min.js');
let pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    methods: {
        // return string that has been matched in the dictionary
        toDictionary(val) {
            let str = '';
            let dictionary = [
                {from: 'α', to: 'a'}, {from: 'β', to: 'b'}, {from: 'γ', to: 'g'}, {from: 'δ', to: 'd'},
                {from: 'ε', to: 'e'}, {from: 'ζ', to: 'z'}, {from: 'η', to: 'h'}, {from: 'θ', to: 'u'},
                {from: 'ι', to: 'i'}, {from: 'κ', to: 'k'}, {from: 'λ', to: 'l'}, {from: 'μ', to: 'm'},
                {from: 'ν', to: 'n'}, {from: 'ξ', to: 'j'}, {from: 'ο', to: 'o'}, {from: 'π', to: 'p'},
                {from: 'ρ', to: 'r'}, {from: 'σ', to: 's'}, {from: 'τ', to: 't'}, {from: 'υ', to: 'y'},
                {from: 'φ', to: 'f'}, {from: 'χ', to: 'x'}, {from: 'ψ', to: 'c'}, {from: 'ω', to: 'v'}
            ];

            for (let i = 0, len = val.length; i < len; i++) {

                //check if current char in dictionary
                let found = dictionary.find(obj => obj.from === val[i] || obj.to === val[i]);

                if (found) {
                    str += found.from === val[i] ? found.to : found.from;
                } else {
                    str += val[i];
                }
            }

            return str;
        },
        // sort array of objects
        sortArrayOfObjects(data) {

            let dateFormat = /(^\d{1,4}[\.|\\/|-]\d{1,2}[\.|\\/|-]\d{1,4})(\s*(?:0?[1-9]:[0-5]|1(?=[012])\d:[0-5])\d\s*[ap]m)?$/;

            return data.array.sort((a, b) => {

                // given property is a date
                if (dateFormat.test(a[data.property]) && dateFormat.test(b[data.property])) {

                    let parts_a = a[data.property].split('/');
                    let parts_b = b[data.property].split('/');

                    let name_a = new Date(parts_a[2], parts_a[1] - 1, parts_a[0]);
                    let name_b = new Date(parts_b[2], parts_b[1] - 1, parts_b[0]);

                    return data.descending ? name_b - name_a : name_a - name_b;

                    // not a date
                } else {

                    let name_a = a[data.property] ? a[data.property].toUpperCase() : a[data.property];
                    let name_b = b[data.property] ? b[data.property].toUpperCase() : b[data.property];

                    return data.descending ? (name_a < name_b ? 1 : name_a > name_b ? -1 : 0)
                        : (name_a < name_b ? -1 : name_a > name_b ? 1 : 0);
                }
            });
        },
        // format given date
        formatDate(date) {
            if (!date) {
                return null;
            }

            const [year, month, day] = date.split('-');
            return `${day}/${month}/${year}`;
        },
        // format file size to appropriate size type
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return `${parseFloat((bytes / Math.pow(k, i)).toFixed([1, 2].indexOf(i) > -1 ? 0 : dm))} ${sizes[i]}`;
        },
        // createSearchFilters
        createSearchFilters(search_values) {
            let filters = {};

            // get all filters that are not null
            for (let prop in search_values) {
                if (search_values[prop] !== null) {
                    if (search_values[prop].constructor === Array) {
                        if (search_values[prop].length > 0) {
                            filters[prop] = search_values[prop];
                        }
                    } else {
                        filters[prop] = search_values[prop];
                    }
                }
            }

            return filters;
        },
        // concert number to words
        convertNumberToWords(amount) {
            let words = [
                '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve',
                'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen', 'Twenty'
            ];
            words[30] = 'Thirty';
            words[40] = 'Forty';
            words[50] = 'Fifty';
            words[60] = 'Sixty';
            words[70] = 'Seventy';
            words[80] = 'Eighty';
            words[90] = 'Ninety';

            amount = amount.toString();
            let atemp = amount.split(".");
            let number = atemp[0].split(",").join("");
            let n_length = number.length;
            let words_string = "";

            if (n_length <= 9) {
                let n_array = [0, 0, 0, 0, 0, 0, 0, 0, 0];
                let received_n_array = [];
                for (let i = 0; i < n_length; i++) {
                    received_n_array[i] = number.substr(i, 1);
                }
                for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
                    n_array[i] = received_n_array[j];
                }
                for (let i = 0, j = 1; i < 9; i++, j++) {
                    if (i == 0 || i == 2 || i == 4 || i == 7) {
                        if (n_array[i] == 1) {
                            n_array[j] = 10 + parseInt(n_array[j]);
                            n_array[i] = 0;
                        }
                    }
                }
                let value = "";
                for (let i = 0; i < 9; i++) {
                    if (i == 0 || i == 2 || i == 4 || i == 7) {
                        value = n_array[i] * 10;
                    } else {
                        value = n_array[i];
                    }
                    if (value != 0) {
                        words_string += words[value] + " ";
                    }
                    if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                        words_string += "Crores ";
                    }
                    if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                        words_string += "Lakhs ";
                    }
                    if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                        words_string += "Thousand ";
                    }
                    if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                        words_string += "Hundred and ";
                    } else if (i == 6 && value != 0) {
                        words_string += "Hundred ";
                    }
                }
                words_string = words_string.split("  ").join(" ");
            }
            return words_string;
        },
        // convert decimal number to words
        numberToWordsWithDecimal(n) {
            let nums = n.toString().split('.');
            let whole = this.convertNumberToWords(nums[0]);

            if (nums.length == 2) {
                let fraction = this.convertNumberToWords(nums[1]);
                return whole + 'and ' + fraction;
            } else {
                return whole;
            }
        },
        // is user able to do given action
        canUserDoAction(permission, isSuperAdmin, item) {

            if (!permission) {
                return false
            }

            if (isSuperAdmin) {
                return true;
            }

            // today's date
            let date = new Date();
            date.setHours(date.getHours() - 1);
            let today = Date.parse(date.toISOString().substring(0, 19));

            // item's created date
            let new_date = new Date(item.created_at ? item.created_at : '1970-01-01T00:00:00');
            let item_date = Date.parse(new_date.toISOString().substring(0, 19));

            return today <= item_date;
        },
        uppercase(e) {
            e.target.value = e.target.value.toUpperCase();
            e.target.dispatchEvent(new Event('input'));
        },
        printReceipt(item) {
            let pdf_document = {
                info: {title: `invoice_${item.number}`, download: `invoice_${item.number}.pdf`},
            };

            pdf_document.pageSize = 'A5';
            pdf_document.pageOrientation = 'portrait';
            pdf_document.pageMargins = [30, 30];
            pdf_document.styles = this.pdfStyles();
            pdf_document.content = [
                this.createHeaderSection(),
                this.createCompanyDetailsSection(),
                this.createReceiptDetailsSection(item),
                this.createReceiptAmountDetailsSection(item),
                this.createTotalAmountDetailsSection(item),
            ];
            pdfMake.createPdf(pdf_document).print();

            const win = window.open('', "tempWinForPdf");
            pdfMake.createPdf(pdf_document).print({}, win);
            win.close();

            // this.$scope.generatePdf = function () {
            //     // create the window before the callback
            //     var win = window.open('', '_blank');
            //     $http.post('/someUrl', data).then(function (response) {
            //         // pass the "win" argument
            //         pdfMake.createPdf(pdf_document).print({}, win);
            //     });
            // };

        },
        createHeaderSection() {
            let logo_canvas = this.createImageCanvas('logo_img');
            return {
                image: logo_canvas.toDataURL('image/png'), width: 370, margin: [0, 2, 0, 0]
            };
        },
        createCompanyDetailsSection() {
            return {
                text: 'Flamoudiou 1, 2546, Dali, Lefkosia | ΑΦΤ: 10401210L',
                style: ['bold', 'align_right', 'font_8'],
                margin: [0, 4, -10, 0]
            };
        },
        createReceiptDetailsSection(item) {
            return {
                table: {
                    body: [
                        [
                            {
                                text: i18n.t('invoices.receipt_number'),
                                style: ['bold', 'align_right', 'font_9'],
                                border: [false, false, true, false]
                            },
                            {
                                text: item.receipt_no,
                                style: ['align_left', 'font_9'],
                                border: [false, false, false, false],
                            }
                        ],
                        [
                            {
                                text: i18n.t('general.date'),
                                style: ['bold', 'align_right', 'font_9'],
                                border: [false, false, true, false]
                            },
                            {
                                text: item.date,
                                style: ['align_left', 'font_9'],
                                border: [false, false, false, false],
                            }
                        ],
                        [
                            {
                                text: i18n.t('clients.singular'),
                                style: ['bold', 'align_right', 'font_9'],
                                border: [false, false, true, false]
                            },
                            {
                                text: item.client_name,
                                style: ['align_left', 'font_9'],
                                border: [false, false, false, false],
                            }
                        ]
                    ],
                },
                margin: [0, 15, 0, 0],
                layout: {
                    vLineColor: () => 'black',
                }
            }
        },
        createReceiptAmountDetailsSection(item) {
            return {
                table: {
                    widths: [30, 185, 60, 60],
                    body: [
                        [
                            {
                                text: i18n.t('invoices.quantity'),
                                style: ['bold', 'align_left', 'font_9'],
                                border: [false, false, false, true]
                            },
                            {
                                text: i18n.t('invoices.description'),
                                style: ['bold', 'align_left', 'font_9'],
                                border: [false, false, false, true],
                            },
                            {
                                text: i18n.t('invoices.price'),
                                style: ['bold', 'align_center', 'font_9'],
                                border: [false, false, false, true],
                            },
                            {
                                text: i18n.t('invoices.total'),
                                style: ['bold', 'align_right', 'font_9'],
                                border: [false, false, false, true],
                            }
                        ],
                        [
                            {
                                text: '1',
                                style: ['align_left', 'font_9'],
                                border: [false, false, false, false]
                            },
                            {
                                text: i18n.t('invoices.description_text') + ' ' + item.invoice_no,
                                style: ['align_left', 'font_9'],
                                border: [false, false, false, false],
                            },
                            {
                                text: item.total,
                                style: ['align_center', 'font_9'],
                                border: [false, false, false, false],
                            },
                            {
                                text: item.total,
                                style: ['align_right', 'font_9'],
                                border: [false, false, false, false],
                            }
                        ]
                    ],
                },
                margin: [0, 25, 0, 0],
                layout: {
                    vLineColor: () => 'black',
                }
            }
        },
        createTotalAmountDetailsSection(item) {
            return {
                table: {
                    widths: [70, 70],
                    body: [
                        [
                            {
                                text: i18n.t('invoices.subtotal'),
                                style: ['bold', 'align_right', 'font_9'],
                                border: [false, false, true, false]
                            },
                            {
                                text: item.subtotal,
                                style: ['align_left', 'font_9'],
                                border: [false, false, false, false],
                            }
                        ],
                        [
                            {
                                text: i18n.t('invoices.total_vat'),
                                style: ['bold', 'align_right', 'font_9'],
                                border: [false, false, true, false]
                            },
                            {
                                text: item.total_vat,
                                style: ['align_left', 'font_9'],
                                border: [false, false, false, false],
                            }
                        ],
                        [
                            {
                                text: i18n.t('invoices.final'),
                                style: ['bold', 'align_right', 'font_9'],
                                border: [false, false, true, false]
                            },
                            {
                                text: item.total,
                                style: ['align_left', 'font_9'],
                                border: [false, false, false, false],
                            }
                        ]
                    ],
                },
                margin: [260, 295, 0, 0],
                layout: {
                    vLineColor: () => 'black',
                }
            }
        },
        // create canvas for the given image id
        createImageCanvas(image_id) {
            let image = document.getElementById(image_id);
            let canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            canvas.getContext('2d').drawImage(image, 0, 0);
            canvas.toDataURL('image/png');
            return canvas;
        }
        ,
        // get object with pdf styles
        pdfStyles() {
            return {
                font_8: {fontSize: 8},
                font_9: {fontSize: 9},
                font_11: {fontSize: 11},
                bold: {bold: true},
                align_right: {alignment: 'right'},
                align_left: {alignment: 'left'},
                align_center: {alignment: 'center'},
            };
        }
    }
}