<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-navigation-drawer v-model="sidebar_drawer" :mini-variant.sync="sidebar_mini_menu" clipped fixed app
                         mini-variant-width="56">

        <template v-slot:prepend>
            <v-list-item dense class="user_slot">
                <v-list-item-icon :title="$store.state.user_details.name">
                    <v-icon color="success">account_circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{$store.state.user_details.name}}</v-list-item-title>
            </v-list-item>
        </template>

        <v-divider class="mb-2"></v-divider>

        <v-list dense>

            <!--Dashboard-->
            <v-list-item to="/" :title="$t('general.dashboard')">
                <v-list-item-icon>
                    <v-icon>dashboard</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{$t('general.dashboard')}}</v-list-item-title>
            </v-list-item>

            <!-- Clients -->
            <v-list-item @click="" to="/clients" :title="$t('clients.plural')">
                <v-list-item-icon>
                    <v-icon>face</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{$t('clients.plural')}}</v-list-item-title>
            </v-list-item>

            <!-- Invoicess -->
            <v-list-item @click="" to="/invoice_list/0" :title="$t('invoices.plural')">
                <v-list-item-icon>
                    <v-icon>receipt_long</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{$t('invoices.plural')}}</v-list-item-title>
            </v-list-item>

            <!-- Calendar -->
            <v-list-item @click="" to="/calendar" :title="$t('general.calendar')">
                <v-list-item-icon>
                    <v-icon>event_note</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{$t('general.calendar')}}</v-list-item-title>
            </v-list-item>

            <!--Settings-->
            <v-list-group prepend-icon="settings" no-action v-if="canManageSettings" :title="$t('general.settings')">
                <template v-slot:activator>
                    <v-list-item-title>{{$t('general.settings')}}</v-list-item-title>
                </template>
                <v-list-item @click="" to="/services">
                    <v-list-item-title>{{$t('services.plural')}}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="" to="/products">
                    <v-list-item-title>{{$t('products.plural')}}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="" to="/product-categories">
                    <v-list-item-title>{{$t('products.categories')}}</v-list-item-title>
                </v-list-item>
            </v-list-group>

        </v-list>

    </v-navigation-drawer>
</template>

<script type="text/babel">
    export default {
        props: ['drawer', 'mini_menu'],
        data: self => ({
            sidebar_drawer: self.drawer,
            sidebar_mini_menu: self.mini_menu,

            // menu permissions
            canManageSettings: false
        }),
        created() {

        },
        async mounted() {
            this.canManageSettings = await this.$store.dispatch('hasPermission', 'manage_settings');
        },
        watch: {
            // change on prop drawer
            drawer(val) {
                this.sidebar_drawer = val;
            },
            // change on prop mini menu
            mini_menu(val) {
                this.sidebar_mini_menu = val;
            },
            // change on drawer in sidebar
            sidebar_drawer(val) {
                this.$emit('drawerChanged', val);
            },
            // change on mini menu in sidebar
            sidebar_mini_menu(val) {
                this.$emit('miniMenuChanged', val);
            }
        }
    }
</script>