import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import store from './store';
import vuetify from './plugins/vuetify';
import './styles/custom_style.scss';
import Auth from './mixins/auth';
import Helper from './mixins/helper';
import './registerServiceWorker';
import DaySpan from 'dayspan-vuetify-2';
import JsonExcel from "vue-json-excel";

Vue.config.productionTip = false;
// Vue.use(Vlf);
Vue.prototype.$Auth = Auth;
Vue.prototype.$Helper = Helper;

Vue.component("downloadExcel", JsonExcel);

Vue.use(DaySpan, {
    methods: {
        getDefaultEventColor: () => '#1976d2'
    }
});

// make app initial set up
store.dispatch('initialSetUp').then(() => {

    // create vue instance
    new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App)
    }).$mount('#app');

}).catch(error => {
    console.log('main.js initialSetUp error: ' + error);
});
